<template>
  <en-dialog :model-value="modelValue" width="80%" title="投诉列表" @close="$emit('update:model-value', false)">
    <table-dynamic
      :height="600"
      code="CMTFD"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
    ></table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/complaint/query',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config:{
          SERIAL_NO: { header: { filter: { type: 'text', field: 'complaintSerialNo' } } },
          SERVICE_SERIAL_NO: {
          header: {
            filter: {
              type: 'text',
              field: 'serviceSerialNo'
            }
          }
        },
        ADVISOR_NAME: {
          header: {
            filter: {
              type: 'text',
              field: 'advisorName'
            }
          }
        },
        VISITOR_NAME: {
          header: {
            filter: {
              type: 'text',
              field: 'visitorName'
            }
          }
        },
        VEHICLE_PLATE_NO: {
          header: {
            filter: {
              type: 'text',
              field: 'plateNo'
            }
          }
        },
        VEHICLE_OWNER_NAME: {
          header: {
            filter: {
              type: 'text',
              field: 'ownerName'
            }
          }
        },
        COMPLAINT_DATE: {
          header: {
            filter: {
              type: 'date',
              field: ['startComplaintDate', 'endComplaintDate'],
              props: { type: 'daterange' }
            }
          }
        },
        VEHICLE_OWNER_CELLPHONE:{
          header: {
            filter: {
              type: 'text',
              field: 'cellphone'
            }
          }
        },
        ENTER_DATETIME: {
          header: {
            filter: {
              type: 'date',
              field: ['startEnterDate', 'endEnterDate'],
              props: { type: 'daterange' }
            }
          }
        },
        WAY: {
            header: {
              filter: {
                type: 'select',
                field: 'way',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['CMPLTBY'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          VALID: {
            header: {
              filter: {
                type: 'select',
                field: 'validCode',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['CPLVAL']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          TARGET: {
            header: {
              filter: {
                type: 'select',
                field: 'targetCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['CPLTGT']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERVICE_MANAGER_NAME: {
          header: {
            filter: {
              type: 'text',
              field: 'serviceManagerName'
            }
          }
        },
        LIABLE_PERSON: {
          header: {
            filter: {
              type: 'text',
              field: 'liablePersonName'
            }
          }
        },
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
